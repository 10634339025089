<template>
  <div>
    <CBreadcrumb :items="links" style="margin-top: -28px;"/>

    <CButton
      class="mb-3 text-center"
      color="success"
      variant="outline"
      square
      size="sm"
      @click="generatePdf"
    >
      Generate PDF
    </CButton>
    
    <CRow>
      <CCol sm="3"></CCol>
      <CCol sm="6">
        <vue-html2pdf
          :show-layout="true"
          :float-layout="false"
          :enable-download="true"
          :preview-modal="false"
          :paginate-elements-by-height="1400"
          filename="Lintasarta-BAST"
          :pdf-quality="2"
          :manual-pagination="true"
          pdf-format="a4"
          pdf-orientation="portrait"
          pdf-content-width="100%"
          @progress="onProgress($event)"
          @hasStartedGeneration="hasStartedGeneration()"
          @hasGenerated="hasGenerated($event)"
          :html-to-pdf-options="htmlToPdfOptions"
          ref="html2Pdf"
        >
          <section slot="pdf-content" id="content">
            <!-- PDF Content Here -->
            <table width="100%">
              <tr>
                <td width="10%">No. Dok.</td>
                <td>FM-LAS-U2-OMT-008-003</td>
                <td rowspan="4" width="40%" align="center">
                  <h4>Formulir Berita Acara<br>Serah Terima Barang<br>Data Center Lintasarta</h4>
                </td>
                <td rowspan="4" width="25%" align="center">
                  <img src="@/assets/icons/icon.png" style="height: 90px;" />
                </td>
              </tr>
              <tr>
                <td width="10%">Versi</td>
                <td>1.3</td>
              </tr>
              <tr>
                <td width="10%">Hal.</td>
                <td>1 dari 1</td>
              </tr>
              <tr>
                <td width="10%">Label</td>
                <td>Internal</td>
              </tr>
            </table>
            
            <table width="100%" class="mt-3">
              <tr>
                <td width="10%" align="center">Lokasi Data Center</td>
                <td>{{visit[0].site_name}}</td>
                <td>Ruangan: {{visit[0].room_id}}</td>
                <td>Rack: {{visit[0].rack}}</td>
              </tr>
              <tr>
                <td width="10%" align="center">Keperluan</td>
                <td colspan="3">
                  <span :class="[bast.status === 1 ? '' : 'coret']">&#183; Masuk / Pasang Perangkat Data Center</span> &nbsp;
                  <span :class="[bast.status === 0 ? '' : 'coret', 'ml-5']">&#183;  Keluar Barang Data Center</span>
                </td>
              </tr>
              <tr>
                <td colspan="4">
                  <br>Pada hari ini, <strong>{{date(bast.updated_at, 'dddd')}}</strong> Tanggal <strong>{{date(bast.updated_at, 'DD')}}</strong> Bulan <strong>{{date(bast.updated_at, 'MMMM')}}</strong> Tahun <strong>{{date(bast.updated_at, 'YYYY')}}</strong>, yang bertandatangan di bawah ini:
                  <br><br>
                  <p class="ml-3">Nama : <strong>{{bast.first_party}}</strong></p>
                  <p class="ml-3 mt-0" >Instansi : <strong>{{bast.status === 0 ? 'Lintasarta' : company_name}}</strong></p>
                  Untuk selanjutnya disebut sebagai <strong>PIHAK PERTAMA</strong>.
                  <p class="ml-3 mt-2">Nama : <strong>{{bast.second_party}}</strong></p>
                  <p class="ml-3 mt-0" >Instansi : <strong>{{bast.status === 1 ? 'Lintasarta' : company_name}}</strong></p>
                  Untuk selanjutnya disebut sebagai <strong>PIHAK KEDUA</strong>.
                  <p class="mt-3 mb-2"><strong>PIHAK PERTAMA</strong> telah menyerahkan kepada <strong>PIHAK KEDUA</strong> barang-barang seperti tersebut di bawah ini :</p>

                  <table width="100%">
                    <tr>
                      <th rowspan="2">No</th>
                      <th rowspan="2">Nama Barang</th>
                      <th rowspan="2">Serial Number / No. Reg LA</th>
                      <th rowspan="2">Rak</th>
                      <th rowspan="2">Jumlah</th>
                      <th colspan="2">Dual Feed</th>
                      <th rowspan="2">Keterangan / Fungsi</th>
                    </tr>
                    <tr>
                      <th>Y</th>
                      <th>N</th>
                    </tr>
                    <tr v-for="row in items" :key="row.id">
                      <td align="center">{{items.map(function(x) {return x.id; }).indexOf(row.id)+1}}</td>
                      <td>{{row.item_name}}</td>
                      <td>{{row.serial}}</td>
                      <td>{{row.rack}}</td>
                      <td align="right">{{row.total}}</td>
                      <td align="center">{{row.isDualFeed === 1 ? '√' : ''}}</td>
                      <td align="center">{{row.isDualFeed === 0 ? '√' : ''}}</td>
                      <td>{{row.note}}</td>
                    </tr>
                  </table>

                  <p class="mt-3">Demikian Berita Acara Serah Terima ini dibuat dan ditandatangani untuk dapat dipergunakan sebagaimana mestinya.</p>

                  <CRow>
                    <CCol sm="4" class="text-center">
                      <h6>PIHAK PERTAMA</h6>
                      <img
                  	    style="height: 50px;"
                  	    :src="'https://dcvisit.lintasarta.net/api/public/' + bast.first_party_sign"
                	    >
                      <p class="mt-3"><strong>{{bast.first_party}}</strong></p>
                    </CCol>
                    <CCol sm="4" class="text-center">
                      
                    </CCol>
                    <CCol sm="4" class="text-center">
                      <h6>PIHAK KEDUA</h6>
                      <img
                  	    style="height: 50px;"
                  	    :src="'https://dcvisit.lintasarta.net/api/public/' + bast.second_party_sign"
                	    >
                      <p class="mt-3"><strong>{{bast.second_party}}</strong></p>
                    </CCol>
                  </CRow>

                </td>
              </tr>
            </table>
            <table width="100%">
              <tr>
                <th class="title" colspan="3">PERSETUJUAN KELUAR BARANG DATA CENTER</th>
              </tr>
              <tr>
                <td valign="top" width="40%">
                  Alasan Persetujuan :
                  <p class="mt-2">{{bast.approval_note}}</p>
                </td>
                <td align="center" width="30%">
                  <p>Petugas Data Center,</p>
                  <img
                  	style="height: 50px;"
                  	:src="'https://dcvisit.lintasarta.net/api/public/' + bast.officer_approval_sign"
                	>
                  <p class="mt-3"><strong>{{bast.officer_approval}}</strong></p>
                </td>
                <td align="center" width="30%">
                  <p>Security LA,</p>
                  <img
                  	style="height: 50px;"
                  	:src="'https://dcvisit.lintasarta.net/api/public/' + bast.security_approval_sign"
                	>
                  <p class="mt-3"><strong>{{bast.security_approval}}</strong></p>
                </td>
              </tr>
            </table>

            <hr />
            <p class="mt-0">
              <strong>HakCipta PT.APLIKANUSA LINTASARTA, Indonesia<br>
              FM-LAS-U2-OMT-008-003 Formulir Berita Acara Serah Terima Barang Data Center Lintasarta</strong>
            </p>

          </section>
        </vue-html2pdf>
      </CCol>
      <CCol sm="3"></CCol>
    </CRow>

  </div>
</template>

<script>
import VueHtml2pdf from 'vue-html2pdf';
import moment from 'moment';

import http from '@/utils/http-common';

export default {
  components: {
    VueHtml2pdf
  },
  data () {
    return {
      id: this.$route.params.id,
      visit: [],
      bast: [],
      items: [],
      company_name: '',
      links: [
        {
          text: 'Home',
          href: '#/'
        }, 
        {
          text: 'List All BAST',
          href: '#/reports/list-bast'
        }, 
        {
          text: 'BAST Preview'
        }
      ],
      htmlToPdfOptions: {
        margin: 0,
        filename: 'Lintasarta-BAST',
        'pdf-quality': '2',
        jsPDF: {
          unit: 'mm',
          format: 'a4',
          orientation: 'portrait'
        },
        html2canvas: {
          dpi: 300,
          letterRendering: true,
          useCORS: true
        }
      },
    }
  },
  methods: {
    date(value, format) {
      return moment(String(value)).locale('Id').format(format);
    },
    getData(id) {
			let self = this;
			return http.get('/visits/' + id)
			.then(function (response) {
				self.visit = response.data.data;
        self.companyDetail(response.data.data[0].company_id);
			}).catch(function (error) {
				console.log(error);
			});
		},
    getBast(id) {
			let self = this;
			return http.get('/handover/' + id)
			.then(function (response) {
        self.bast = response.data.data;
				self.items = response.data.data.detail;
        self.getData(response.data.data.visit_id);
			}).catch(function (error) {
				console.log(error);
			});
		},
    companyDetail(id) {
      const self = this;
			return http.get('/companies/' + id)
			.then(function (response) {
				self.company_name = response.data.data['company_name'];
			}).catch(function (error) {
				console.log(error);
			});
    },
    generatePdf () {
      this.$refs.html2Pdf.generatePdf();
    },
  },
  mounted: function () {
    this.getBast(this.id);
  }
}
</script>

<style scoped>
#content {
  background: #fff;
  font-size: 7.5px;
  padding: 12px;
  margin-bottom: 12px;
}

th, td {
  border: 0.5px solid black;
  border-collapse: collapse;
}

th, td {
  padding: 4px;
}

th {
  text-align: center;
}

.title {
  text-align: left;
  background: #333;
  color: #fff;
  font-weight: bold;
}

table.center {
  margin-left: auto;
  margin-right: auto;
}

.coret {
  text-decoration: line-through;
}
</style>
